import { Environment } from './environment'

export const localEnvironment: Environment = {
  name:'DEV',
  cdnBaseUrl: 'http://localhost:5003',
  msxEngagementUrl:
    'https://msxuat.crm.dynamics.com/main.aspx?appid=fe0c3504-3700-e911-a849-000d3a10b7cc&newWindow=true&pagetype=entityrecord&etn=msp_engagement&id=',
  msxMilestoneUrl:
    'https://msxuat.crm.dynamics.com/main.aspx?appid=fe0c3504-3700-e911-a849-000d3a10b7cc&newWindow=true&pagetype=entityrecord&etn=msp_engagementmilestone&id=',
  ServiceEndPoints: [
    {
      clientId: '6f40053e-5319-40e5-a90b-6f714506d96d',
      serviceEndPoint: 'https://availabilityaurorasit.azurewebsites.net/',
    },
    {
      clientId: '38578c27-d821-45d3-95db-fcf0e8371e68',
      serviceEndPoint: 'https://grm-queue-sit.trafficmanager.net/',
    },
    {
      clientId: '99b7fa0a-a125-4f7a-abea-efb429bb8972',
      serviceEndPoint: 'https://oneprofileuatcoreapi.microsoft.com/api/v1',
    },
    {
      clientId: '99b7fa0a-a125-4f7a-abea-efb429bb8972',
      serviceEndPoint: 'https://oneprofileuatapipack.azurefd.net/',
    },
    {
      clientId: '38578c27-d821-45d3-95db-fcf0e8371e68',
      serviceEndPoint: 'https://grmapiappsit.azurewebsites.net/',
    },
    {
      clientId: '01af0a61-6437-440a-8171-2628c2c91bf0',
      serviceEndPoint:
        'https://professionalservicesint.microsoft.com/o2c/purchaseorder-sit/api/v1/PurchaseOrders',
    },
    {
      clientId: '8864560e-035f-4bef-a992-fac84af65259',
      serviceEndPoint: 'https://professionalservicesint.microsoft.com/lmssit/',
    },
    {
      clientId: '5eb170f9-f828-4121-936c-288eb43b050e',
      serviceEndPoint:
        'https://professionalservicesint.microsoft.com/Time2-SIT',
    },
    {
      serviceEndPoint:
        'https://professionalservicesint.microsoft.com/o2c/projectmanagementsit/api/',
      clientId: 'f9f20a9f-d0d3-4633-ba06-c211afb45bb4',
    },
    {
      serviceEndPoint:
        'https://professionalservicesint.microsoft.com/o2c/dealmgmt/sit/',
      clientId: '236c3b9e-034e-46e2-97da-af458f1cc27b',
    },
    {
      serviceEndPoint: 'https://metrics-sit-wus.azurewebsites.net',
      clientId: '38578c27-d821-45d3-95db-fcf0e8371e68',
    },
    {
      serviceEndPoint: 'https://feedback-api-preprod-eus.azurewebsites.net',
      clientId: '6f40053e-5319-40e5-a90b-6f714506d96d',
    },
    {
      clientId: '38578c27-d821-45d3-95db-fcf0e8371e68',
      serviceEndPoint: 'https://ps-sms-api-int.azurewebsites.net',
    },
    {
      clientId: '38578c27-d821-45d3-95db-fcf0e8371e68',
      serviceEndPoint: 'https://grmapiappsit.azurewebsites.net/',
    },
    {
      clientId: 'ef8a1b99-cb01-4f9f-80aa-22ee1d628906',
      serviceEndPoint:
        'https://professionalservicesint.microsoft.com/offers/api/queries',
    },
    {
      clientId: '38578c27-d821-45d3-95db-fcf0e8371e68',
      serviceEndPoint: 'https://esxp-sit.azurefd.net/grm/staffing',
    },
    {
      clientId: '6f40053e-5319-40e5-a90b-6f714506d96d',
      serviceEndPoint:
        'https://integration-platform-gateway-api-ppe-eus.azurewebsites.net',
    },
    {
      clientId: '07ef52f9-8b5c-4767-b1c7-5f8450850c42',
      serviceEndPoint: 'https://pursuitexpappservicesit.azurewebsites.net',
    },
    {
      clientId: 'https://loki.delve.office.com/',
      serviceEndPoint: 'https://loki.delve.office.com//user.read.all',
    },
    {
      clientId: '38578c27-d821-45d3-95db-fcf0e8371e68',
      serviceEndPoint: 'https://esxp-sit.azurefd.net',
    },
    {
      clientId: '00000003-0000-0000-c000-000000000000',
      serviceEndPoint: 'https://graph.microsoft.com/v1.0/',
    },
    {
      clientId: 'https://msxuat.crm.dynamics.com',
      serviceEndPoint: 'https://msxuat.crm.dynamics.com/api/data',
    },
    {
      clientId: 'https://msitsalesds-us-prod.cloudapp.net/',
      serviceEndPoint: 'https://coresalesapisuat.azure-api.net',
    },
    {
      clientId: '6f40053e-5319-40e5-a90b-6f714506d96d',
      serviceEndPoint: 'https://fxpconfig-api-preprod-eus.azurewebsites.net/',
    },
    {
      clientId: '38578c27-d821-45d3-95db-fcf0e8371e68',
      serviceEndPoint: 'https://preferenceapippe.azurewebsites.net/',
    },
    {
      clientId: '38578c27-d821-45d3-95db-fcf0e8371e68',
      serviceEndPoint: 'https://vergefeedbackserviceppe.azurewebsites.net/',
    },
    {
      clientId: '99b7fa0a-a125-4f7a-abea-efb429bb8972',
      serviceEndPoint: 'https://vergegamificationservicepp.azurewebsites.net',
    },
    {
      clientId: '99b7fa0a-a125-4f7a-abea-efb429bb8972',
      serviceEndPoint: 'https://localhost:44337',
    },
    {
      clientId: 'dc9bc535-225a-4eac-80b2-f2d787a3e20c',
      serviceEndPoint: 'https://esxp-sit.microsoft.com/cpq/',
    },
    {
      clientId: 'c1981655-bcfb-4d80-b3e4-3e09facdc7a2',
      serviceEndPoint: 'https://esxp-sit.microsoft.com/clm/contractprep-service/',
    },
    {
      clientId: 'c8955af6-b4a7-4e96-86b7-ad0930c25fa7',
      serviceEndPoint: 'https://dmsitafd.azurefd.net/'
    }
  ],
  AuthExceptionEndpoints: [{ serviceEndPoint: 'assets/config.json' }],
}
